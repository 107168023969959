.Wrapper {
    width: 100%;
    justify-content: center;
    padding-bottom: 5px;
    z-index: 10;
    display: none;
}

.BarOutline {
    display: flex;
    justify-self: center;
    border-radius: 40px;
    background-color: white;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 90%;
    max-width: 1000px;
}

.ProgressBar {
    height: 100%;
    min-width: 35px;
    background: linear-gradient(270deg, #F9B9F2 -24.95%, #5603AD 100%);
    border-radius: inherit;
}