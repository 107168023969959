.Connected {
    display: flex;
    flex-direction: row;
}

.Connected:hover {
    cursor: pointer;
}

.Stale {
    margin-left: 0.25rem;
}

.StaleHidden {
    display: none;
}