.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  z-index: 100;
  background-color: white;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 15rem);
  width: 30rem;
  top: 50%;
  transform: translate(0, -50%);
}

.modal h3 {
  font-size: xx-large;
  font-weight: bold;
}

.content {
  padding: 1rem 1rem .2rem 1rem;
}

.modal .closeButton {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
}

.modal button img {
  width: 1rem;
  height: auto;
}

@media (max-width: 992px) {
  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: translate(0, 0);
  }
}