.walletArea {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.walletConnectWrapper {
  display: flex;
  align-items: center;
}

.clearDataBtn {
  width: 100%;
  padding: 8px;
  margin-top: 16px;
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.clearDataBtn:hover {
  background-color: #f5f5f5;
  border-color: #d0d0d0;
} 