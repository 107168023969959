.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.bgGrey{
  background-color: #d5d7e1;
}

.bgBeige {
  background-color: #e1d7d5;
}
