.walletButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.walletButton:hover {
  background-color: #f5f5f5;
  border-color: #d0d0d0;
}

.walletButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  font-size: 24px;
  margin-right: 12px;
}

.text {
  font-size: 16px;
  font-weight: 500;
  color: #333;
} 