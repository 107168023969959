button {
    padding: 0;
}


.voteButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;

    height: 90px;
    width: 50%;
    min-width: 85px;
    max-width: 250px;

    box-sizing: border-box;
}

@media (hover: hover) {
    .voteButton:enabled:hover:not(.selected){
        background: rgba(0, 0, 0, 0.1);
    }
}
.voteText{
    font-size: 1em;
    font-weight: bold;
}

.voteEmojiText{
    font-size: 2em;
}

.selected{
    border: 2px solid black;
    background: rgb(127, 197, 156, .4);
}

@media only screen and (min-width: 768px) {
    .voteButton{
        height: 110px;
    }
    .voteText{
        font-size: 1.5em;
        font-weight: bold;
    }

    .voteEmojiText{
        font-size: 3em;
    }
}