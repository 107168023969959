/*.VoteBar {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    width: 30%;
    min-height: 80px;
    min-width: 320px;
    padding: .8em;
    border-radius: 20px;
    border: 1px solid #000000;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.VoteBarOverlay {
    background-color: white;
    opacity: 0.5;
}

.reconnect {
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: Roboto Mono;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
}*/

.VoteBar {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    width: 30%;
    min-height: 80px;
    min-width: 320px;
    padding: .8em;
    border-radius: 20px;
    border: 1px solid #000000;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.VoteBarOverlay {
    background-color: white;
    opacity: 0.5;
}

.reconnect {
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: Roboto Mono;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
}