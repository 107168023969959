.ToastWrapper {
    padding: 0.5rem 1rem;
    align-items: center;
    border-radius: 9999px;
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.Text {
    font-weight: bold;
    margin-bottom: 0px;
    padding-bottom: 1px;
    text-align: center;
}

.Icon {
    height: 24px;
    width: 24px;
}

.Link {
    text-decoration: none;
    cursor: pointer;
}

.Frontrun {
    background-color: var(--fomo-alarm-background);
    color: var(--fomo-alarm-text);
    stroke: var(--fomo-alarm-text);
}

.Bid {
    background-color: var(--brand-black);
    color: white;
    stroke: white;
}