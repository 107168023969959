.HeaderBar {
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
}

.fundsLow {
  color: red;
}

.navBarBrand {
  position: relative;
  z-index: 2;
  padding: 5px;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-size: 0.9rem;
  padding: 0.5rem 0.8rem !important;
  color: var(--brand-black) !important;
}

.nounsNavLink:hover {
  color: var(--brand-dark-red) !important;
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nogglesAscii {
  display: inline-block;
  position: relative;
  bottom: 1px;
  right: 3px;
}